<header>
    <div class="container bg-darknavyblue">
        <nav class="navbar navbar-expand-lg navbar-dark bg-darknavyblue d-flex flex-column">
            <div class="d-flex w-100 justify-content-between align-items-center">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <!-- Logo and Text -->
                    <a class="navbar-brand d-flex align-items-center text-nowrap" routerLink="home" aria-label="Home"
                        style="font-size: clamp(1rem, 4vw, 1.5rem);">
                        <img src="/assets/images/misc/w-logo-transparent_2.png" alt="Wilsonville High School Logo"
                            width="50" height="50" class="me-2">
                        WILSONVILLE FOOTBALL
                    </a>
                    <!-- Wildcat Store Link -->
                    <!-- <a class="nav-link d-none d-lg-flex flex-column align-items-center" href="https://wilsonvillehighschoolfootball.gearupsports.net/" 
                        target="_blank" aria-label="Get Wildcat Gear">
                        <img src="/assets/images/misc/wilsonvillefootballgearstore.jpg" alt="Shop Wildcat Gear"
                             width="100" class="mb-2" />
                        <span style="font-size: 1rem; font-weight: bold; color: white;">WILDCAT STORE</span>
                    </a> -->
                        <div style="color:#ffffff; text-align: right;">Team GPA: 3.24
                        </div>
                </div>
                <!-- Hamburger Menu -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsibleNavbar" aria-controls="collapsibleNavbar" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <!-- Collapsible Content -->
            <div class="collapse navbar-collapse w-100 mt-2" id="collapsibleNavbar">
                <ul class="navbar-nav w-100">
                    <!-- CALENDAR Menu -->
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" id="calendarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                            CALENDAR
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="calendarDropdown">
                            <li><a class="dropdown-item" routerLink="varsitycalendar">VARSITY MONTHLY CALENDAR</a></li>
                            <li><a class="dropdown-item" routerLink="varsitygame">VARSITY GAME SCHEDULE</a></li>
                            <li><a class="dropdown-item" routerLink="jvcalendar">FROSH/SOPH MONTHLY CALENDAR</a></li>
                            <li><a class="dropdown-item" routerLink="jvgame">FROSH/SOPH GAME SCHEDULE</a></li>
                            <li><a class="dropdown-item" routerLink="summercamps">SUMMER CAMPS</a></li>
                        </ul>
                    </li>
                    <!-- ROSTERS Menu -->
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" id="rostersDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                            ROSTERS
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="rostersDropdown">
                            <li><a class="dropdown-item" routerLink="coachingstaff">COACHING STAFF</a></li>
                            <li><a class="dropdown-item" routerLink="varsityroster">VARSITY ROSTER</a></li>
                            <li><a class="dropdown-item" routerLink="jvroster">FROSH/SOPH ROSTER</a></li>
                        </ul>
                    </li>
                    <!-- GET INVOLVED Menu -->
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" id="involvedDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                            GET INVOLVED
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="involvedDropdown">
                            <li><a class="dropdown-item" routerLink="sponsorlist">SPONSORS</a></li>
                            <li><a class="dropdown-item" routerLink="donate">DONATE NOW</a></li>
                            <li><a class="dropdown-item" routerLink="reservedseating">RESERVED SEATING</a></li>
                            <li><a class="dropdown-item" routerLink="sponsor">BANNER &amp; CORPORATE SPONSORSHIP</a>
                            </li>
                            <li><a class="dropdown-item" routerLink="about">TOUCHDOWN CLUB - WHAT'S THAT?</a></li>
                        </ul>
                    </li>
                    <!-- Standalone Menu Items -->
                    <li class="nav-item">
                        <a class="nav-link" routerLink="alumni">ALUMNI</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="links">LINKS</a>
                    </li>
                </ul>
            </div>
        </nav>

    </div>
</header>